<ng-container *ngIf="actions.length > 0">
    <div ngbDropdown container="body" class="d-inline-block">
      <button [disabled]="disabled" class="btn {{btnClass}}" id="actions-{{labelBy}}" ngbDropdownToggle (click)="preventClick($event)"><i class="fa {{iconClass}}" aria-hidden="true"></i></button>
      <div ngbDropdownMenu attr.aria-labelledby="actions-{{labelBy}}">
        <button ngbDropdownItem *ngFor="let action of nonAdminActions" (click)="performAction($event, action)">{{action.title}}</button>
        <div class="dropdown-divider" *ngIf="nonAdminActions.length > 1 && adminActions.length > 1"></div>
        <button ngbDropdownItem *ngFor="let action of adminActions" (click)="performAction($event, action)">{{action.title}}</button>
      </div>
    </div>
    <!-- TODO: If we are not on desktop, then let's open a bottom drawer instead-->
</ng-container>